$(function () {

    /*var header = $('#header');
    var scrollTimeout = undefined;
    var lastScrollTop = 0;
    $(window).scroll(function(e){
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(function () {
            var st = $(this).scrollTop();
            header.toggleClass('header-collapse', st > lastScrollTop);
            lastScrollTop = st;
        }, 100);
    });*/

    //var sticky = new Sticky('.subnav');

    $('#getintouch').on('click touch', function (e) {
        e.preventDefault();
        var slide = $('.getintouch').hasClass('active') ? 'slideUp' : 'slideDown';
        $('.getintouch').toggleClass('active');
        $('.getintouch').velocity(slide, 300);
    });

    $('#main').on('click touch', function (e) {
        if($('.getintouch').hasClass('active')) {
            e.preventDefault();
            $('.getintouch').removeClass('active');
            $('.getintouch').velocity('slideUp', 300);
        }
    });

    $('#nav_toggle').on('click touch', function () {
        $('.main-nav-mobile').toggleClass('open');
    });

    $('#main, .main-nav-close, .main-nav-mobile a').on('click touch', function () {
        if($('.main-nav-mobile').hasClass('open')) {
            $('.main-nav-mobile').removeClass('open');
        }
    });

    $('.subnav a').on('click touch', function (e) {
        e.preventDefault();
        var el = $(this).attr('href');
        $(el).velocity("scroll", { duration: 500, offset: -120, easing: "ease-out" })
    });

    $('.ep_toggle').on('click touch', function(e) {
        e.preventDefault();
        var toggle = $(this);
        if(!toggle.hasClass('open')) {
            $('.companies').velocity('slideDown', 300);
        } else {
            $('.companies').velocity('slideUp', 300);
        }
        toggle.toggleClass('open');
    });

    // TABS AND ACCORDION

    $('.accordion').each(function () {
        var el = $(this);
        var items = el.find('.accordion-item');
        var tabs = el.find('.accordion-tab');
        var handles = items.find('.accordion-handle');
        var toggles = items.find('.accordion-toggle');

        var ac = 'accordion-open';
        var aic = 'accordion-item-open';
        toggles.not('.' + ac).hide();

        var at = 'accordion-tab-active';
        var open = toggles.filter('.' + ac);
        if(open.length) {
            tabs.eq(open.index() - 1).addClass(at);
        }

        var parent = open.closest('.accordion-item');
        if(!parent.hasClass(aic)) {
            parent.addClass(aic);
            parent.find('.icon-more').addClass('icon-more-collapsed');
        }

        tabs.on('click touch', function () {
            console.log($(this));
            var tab = $(this);
            var item = el.find('.accordion-item').eq(tab.index());
            var toggle = items.find('.accordion-toggle').eq(tab.index());
            if(!toggle.hasClass(ac)) {
                el.find('.' + aic).removeClass(aic);
                el.find('.' + ac).removeClass(ac).hide();
                item.addClass(aic);
                toggle.addClass(ac).show();
                tabs.removeClass(at);
                tab.addClass(at);
            }
        });

        handles.on('click touch', function () {
            var item = $(this).closest('.accordion-item');
            var toggle = item.find('.accordion-toggle');
            if(!toggle.hasClass(ac)) {
                el.find('.' + aic).removeClass(aic);
                el.find('.icon-more').removeClass('icon-more-collapsed');
                el.find('.' + ac).removeClass(ac).velocity('slideUp');
                item.addClass(aic);
                item.find('.icon-more').addClass('icon-more-collapsed');
                toggle.addClass(ac).velocity('slideDown');
                setTimeout(function () {
                    item.velocity("scroll", { duration: 500, offset: -100, easing: "ease-out" });
                }, 500);
            }
        });
    });

    // FLIPBOX

    $('.flip-box').on('click touch mouseenter', function () {
        $('.flip-box.hover').removeClass('hover');
        $(this).toggleClass('hover');
    });

    $('.flip-box').on('mouseleave', function () {
        $('.flip-box.hover').removeClass('hover');
    });

    // SYNC HEIGHT

    function syncHeight(els, to, fix) {

        var timeout = undefined;
        function syncHeightCallback(els, to, fix) {
            clearTimeout(timeout);
            timeout = setTimeout(function () {
                var items = $(els);
                var rule = fix ? 'height' : 'minHeight';
                items.css(rule, 'auto');
                var maxHeight = 0;
                if(to) {
                    maxHeight = $(to).outerHeight();
                } else {
                    items.each(function () {
                        var height = $(this).outerHeight();
                        console.log(height);
                        if(height > maxHeight) {
                            maxHeight = height;
                        }
                    });
                }

                items.css(rule, maxHeight);
            }, 500);
        }

        $(window).on('resize', function () {
            syncHeightCallback(els, to, fix);
        });
    }

    // FIT HEIGHT

    function fitHeight(els) {

        var timeout = undefined;
        function fitHeightCallback(els) {
            clearTimeout(timeout);
            timeout = setTimeout(function () {
                console.log('FIT:' + els);
                $(els).css('minHeight', 'auto').each(function () {
                    var el = $(this);
                    var height = el.parent().innerHeight();
                    el.css('minHeight', height);
                });
            }, 500);
        }

        $(window).on('resize', function () {
            fitHeightCallback(els);
        });

    }

    syncHeight('.flip-front, .flip-back');
    syncHeight('.flip', '.flip-front');

    $('.work_item.work_item_box').on('click touch', function () {
        var item = $(this);
        var cite = 'slideUp';
        var feedback = 'slideDown';
        if(item.hasClass('active')) {
            cite = 'slideDown';
            feedback = 'slideUp';
            item.find('.inside').css('top', '');
        } else {
            item.find('.inside').velocity({top: 0}, {duration: 200});
        }
        item.find('.work_cite').velocity(cite, 200);
        item.find('.work_feedback').velocity(feedback, 200);
        item.toggleClass('active');
        item.find('.icon-more').toggleClass('icon-more-collapsed');
    });

    // SLIDERS

    $('.slider-fullscreen').slick({
        appendArrows: $('.slider-nav')
    });

    $('.slider-fullscreen').find('.slick-next').on('click touch', function () {
        $('.slider-fullscreen').slick('slickNext');
    });

    $('.slider-fullscreen').find('.slick-prev').on('click touch', function () {
        $('.slider-fullscreen').slick('slickPrev');
    });

    /*$('.slider-home-wide').slick({
        infinite: false,
        slidesToShow: 1,
        arrows: false,
        focusOnSelect: true,
        dots: true,
        dotsClass: 'slick-dots slider-scrollbar',
        appendDots: $('.slider-scrollbar-home-wide'),
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    dots: false,
                    arrows: true
                }
            }
        ]
    });*/

    $('.slider-home-wide').slick({
        infinite: true,
        slidesToShow: 1,
        centerMode: true,
        variableWidth: true,
        focusOnSelect: true,
        speed: 300,
        arrows: true,
        //dots: true,
        //dotsClass: 'slick-dots slider-scrollbar',
        //appendDots: $('.slider-scrollbar-branches'),
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    centerMode: false,
                    variableWidth: false,
                    slidesToShow: 1
                }
            }
        ]
    });

    $('.slider-home').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
        //appendArrows: $('.slider-home-box .slider-nav')
    });

    syncHeight('.slider-home-wide .teaser-home-wide .col-12');
    syncHeight('.slider-home-wide .teaser-home-wide');
    syncHeight('.slider-home .teaser-home');

    $('.slider-relations').slick({
    });

    syncHeight('.slider-relations .inside');

    /*$('.slider-fleet').slick({
        infinite: false,
        slidesToShow: 2,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });*/

    $('.slider-fleet').slick({
        infinite: true,
        slidesToShow: 3,
        centerMode: true,
        variableWidth: true,
        focusOnSelect: true,
        speed: 300,
        arrows: true,
        //dots: true,
        //dotsClass: 'slick-dots slider-scrollbar',
        //appendDots: $('.slider-scrollbar-branches'),
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    dots: false,
                    arrows: true
                }
            },
            {
                breakpoint: 576,
                settings: {
                    centerMode: false,
                    variableWidth: false,
                    slidesToShow: 1
                }
            }
        ]
    });

    syncHeight('.slider-fleet .lok');
    syncHeight('.slider-fleet .wagen');
    //syncHeight('.fuhrpark-intro','.slider-fleet');

    /*$('.slider-branches').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        console.log(event);
        var current = slick.$slides[currentSlide];
        var next = slick.$slides[nextSlide];
        $(current).removeClass('slick-expand').find('p').velocity('slideUp');
        $(next).addClass('slick-expand').find('p').velocity('slideDown');

        $('.slider-branches').slick('slickGoTo', nextSlide);
    });*/

    $('.slider-slidedown').each(function () {
        var el = $(this);
        var current = undefined;
        var timeout = undefined;

        var scaleSlider = function(current, index) {
            el.find('.slick-expand').removeClass('slick-expand').find('p').velocity('slideUp', 200);
            $(current).addClass('slick-expand');

            clearTimeout(timeout);
            timeout = setTimeout(function () {
                $(current).find('p').velocity('slideDown', 500);
                el.slick('slickGoTo', index);
            }, 300);
        }

        var active = el.find('.slick-slide').eq(0);
        scaleSlider(active, 0);

        el.on('afterChange', function(event, slick, currentSlide){
            if(current !== currentSlide) {
                current = currentSlide;
                var active = slick.$slides[currentSlide];
                scaleSlider(active, currentSlide);
            }
        });
    });

    $('.slider-branches').slick({
        infinite: true,
        slidesToShow: 3,
        arrows: false,
        centerMode: true,
        variableWidth: true,
        focusOnSelect: true,
        speed: 300,
        arrows: true,
        //dots: true,
        //dotsClass: 'slick-dots slider-scrollbar',
        //appendDots: $('.slider-scrollbar-branches'),
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    dots: false,
                    arrows: true
                }
            }
        ]
    });

    $('.slider-expertise').slick({
        infinite: true,
        slidesToShow: 3,
        arrows: false,
        centerMode: true,
        variableWidth: true,
        focusOnSelect: true,
        speed: 300,
        arrows: true,
        //dots: true,
        //dotsClass: 'slick-dots slider-scrollbar',
        //appendDots: $('.slider-scrollbar-expertise'),
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    dots: false,
                    arrows: true
                }
            }
        ]
    });

    syncHeight('.slide-expertise');

    syncHeight('.reason-box .box');
    fitHeight('#contact .learn_more');
    fitHeight('.fit-height');

    $(window).trigger('resize');


    $('.slick-slider a').on('click touch', function (e) {
        var url = $(this).attr('href');
        //console.log(window.location.protocol + '//' + window.location.hostname + window.location.port + url);
        if(url) {
            window.location.assign(url);
        }
    });

    /*$('.slider-expand-right').each(function () {
        var el = $(this);
        var timeout = undefined;
        function adjustSlider() {
            clearTimeout(timeout);
            timeout = setTimeout(function () {
                el.find('.slick-list').css('overflow', 'visible');
            }, 100);
        }

        adjustSlider();
        $(window).on('resize', adjustSlider);
    });*/


    // MAP

    var station = undefined;
    var stationEl = $('.station-info');

    $('.station').on('click touch', function (e) {
        var s = $(this);
        var name = s.attr('rel');
        $('.station').removeClass('active');
        if(station == name) {
            stationEl.css('opacity', 0);
        } else {
            station = name;
            s.addClass('active');
            stationEl.text(station).css('opacity', 1);
            console.log(station);
        }
    });

    $('.station').on('mouseenter', function (e) {
        var s = $(this);
        var name = s.attr('rel');
        stationEl.text(name).css('opacity', 1);
        //stationEl.text(name).show();
        console.log(name + ' [' + s.attr('custom:x') + ':' + s.attr('custom:y') + ']');
    }).on('mouseleave', function (e) {
        stationEl.css('opacity', 0);
    });

    $('.relation').on('click touch', function () {
        $('.relation').removeClass('active');
        $('.relation').each(function () {
            var c = $(this).attr('custom:color');
            $(this).attr('stroke', c);
        });
        var r = $(this);
        var i = r.attr('rel');
        r.addClass('active');
        var a = r.attr('custom:color-active');
        r.attr('stroke', a);
        console.log(i);
        $('.slider-relations').slick('slickGoTo', i - 1);
    });

    $('.slider-relations').on('beforeChange', function(event, slick, current, next){
        if(current !== next) {
            console.log(current, next);
            var currentPath = $('.rel-path-' + (current + 1));
            currentPath.removeClass('active');
            currentPath.attr('stroke', currentPath.attr('custom:color'));
            var nextPath = $('.rel-path-' + (next + 1));
            nextPath.addClass('active');
            nextPath.attr('stroke', nextPath.attr('custom:color-active'));
        }
    });

    // BAULOGISTIK

    /*var regions = {};
    axios.get('/!/Fetch/collection/construction').then(function (response) {
       console.log(response);
       var data = response.data.data;
       $.each(data, function (i, el) {
           if(!regions[el.region.slug]) {
               regions[el.region.slug] = {
                   id: el.region.id,
                   data: []
               };
           }
           regions[el.region.slug].data.push(el);
            console.log(el.title);
       });
       console.log(regions);

       var boxes = '';
       $.each(regions, function (i, re) {
            boxes += '<div class="box-location loc-slide-' + re.id + '">' +
            '<div class="slider-construction">';
            $.each(regions, function (i, re) {
                boxes += '<div class="slide-construction">' +

                    '</div>';
            });
            boxes += '</div>' +
                '</div>';
       });

    });*/

    /*var regions = $('.regions');
    var cbox = $('.constructions');
    var citems = cbox.find('.slide-construction');

    citems.each(function () {
        var region = $(this).attr('rel');
        $(this).appendTo('.regions .region-' + region + ' .slider-construction');
        console.log(region);
    });

    regions.children().each(function () {
        var id = $(this).attr('rel');
        var count = $(this).find('.slider-construction').children().length;
        var txt = $('#region-count-' + id);
        if(count > 1) {
            $('#region-circle-' + id).attr('r', 45);
            txt.text(count);
            $(this).data('count', count);
            $(this).find('.slide-counter').show().find('.slide-total').html(count);
        } else {
            txt.attr('opacity', 0);
        }
    });

    $('.slider-construction').each(function () {
        var id = $(this).attr('rel');
        if($(this).children().length > 1) {
            $(this).children().each(function () {
                $(this).find('.slide-index').html($(this).index() + 1);
            });
            $(this).slick();
        }
    });

    var regionEl = regions.children().first();
    regionEl.addClass('active').show();
    var region = regionEl.attr('rel');
    $('#' + region).addClass('active');

   $('.construction').on('click touch', function () {
       $('.construction').removeClass('active');
       var c = $(this);
       c.addClass('active');
       var r = c.attr('id');
       if(r != region) {
           regions.find('.active').removeClass('active').velocity('fadeOut', {
               duration: 200,
               complete: function () {
                   regions.find('.region-' + r).addClass('active').velocity('fadeIn');
               }
           });
           region = r;
       }
   });

   $('.slider-construction').on('beforeChange', function(event, slick, current, next){
       if(current !== next) {
           var region = $(slick.$slides[next]).attr('rel');
           //$('.map .construction').removeClass('active');
           //$('#' + region).addClass('active');
       }
   });

    $('.slider-construction p, .slider-construction span').on('click touch mouseenter', function () {
        var info = $(this).attr('rel');
        if(info) {
            $(this).closest('.slider-construction').find('.box-orange').html(info);
        }
    });

    $('.slider-construction').on('mouseleave', function () {
        $(this).find('.box-orange').html('&nbsp;');
    })*/

});
